import "./App.css";
import AboutUs from "./Components/Hero/AboutUs/AboutUs";
import Hero from "./Components/Hero/Hero";
import Navbar from "./Components/Navbar/Navbar";
import Services from "./Components/Services/Services";
import ContactUs from "./Components/ContactUs/ContactUs";
import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <>
      <Navbar />
      <section id="home">
        <Hero />
      </section>
      <section id="aboutus">
        <AboutUs />
      </section>
      <section id="services">
        <Services />
      </section>

      <section id="contact">
        <ContactUs />
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
}

export default App;
