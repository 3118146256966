import React from "react";
import "./Services.css";
import MedicalEquip from "./medical-equip.png";
import ProjectMan from "./project-man.png";
import projconsult from "./proj-consult.png";
import StratImplement from "./strat-implement.png";
const serviceData = [
  {
    title: "Medical supplies",
    description:
      "Provide high-quality medical supplies to cater to diverse needs.",
    image: MedicalEquip,
  },
  {
    title: "Project Management",
    description:
      "Expertise in managing projects to ensure timely and effective delivery.",
    image: ProjectMan,
  },
  {
    title: "Project Consultation and Design",
    description:
      "Offering consultation and design solutions tailored to your project needs.",
    image: projconsult,
  },
  {
    title: "Strategic Implementation",
    description: "Strategically implementing solutions for optimum results.",
    image: StratImplement,
  },
];

function Services() {
  return (
    <section id="services" className="services-section">
      <h2>Our Services</h2>
      <div className="services-container">
        {serviceData.map((service, index) => (
          <div
            key={index}
            className={`service-card ${index % 2 === 0 ? "even" : "odd"}`}
          >
            <img src={service.image} alt={service.title} />
            <div className="service-content">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
