import React from 'react';
import { FaTrademark, FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import './Footer.css';

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-brand">
          <h2>CCINVEST <FaTrademark /></h2>
        </div>
        <p className="footer-text">Illuminate Your Path with CCINVEST. Pioneering Solutions in Medical Supplies, Project Management, and Strategic Implementation.</p>
        <div className="footer-icons">
          <a href="#" aria-label="Facebook" target="_blank" rel="noreferrer"><FaFacebookF /></a>
          <a href="#" aria-label="Twitter" target="_blank" rel="noreferrer"><FaTwitter /></a>
          <a href="#" aria-label="LinkedIn" target="_blank" rel="noreferrer"><FaLinkedinIn /></a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
