import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaGlobe } from 'react-icons/fa';
import './ContactUs.css';
import ContactImage from './img_contactus.jpg'; // Import your flavor image

function ContactUs() {
  return (
    <div className="contact-container">
      <img src={ContactImage} alt="Contact Illustration" className="contact-image" />
      <div className="contact-header">Contact Us</div>
      <div className="contact-content">
        {renderContactItem(FaPhoneAlt, "+971 556505286")}
        {renderContactItem(FaEnvelope, "Sales@CCInvestPms.com", "mailto:Sales@CCInvestPms.com")}
        {renderContactItem(FaEnvelope, "Info@CCInvestPms.com", "mailto:Info@CCInvestPms.com")}
        {renderContactItem(FaGlobe, "www.CCInvestpms.com", "http://www.CCInvestpms.com")}
      </div>
    </div>
  );
}

function renderContactItem(Icon, text, link) {
  return (
    <div className="contact-item">
      <Icon className="contact-icon"/>
      {link ? 
        <a href={link} target="_blank" rel="noreferrer">{text}</a> : 
        <span>{text}</span>
      }
    </div>
  );
}

export default ContactUs;
