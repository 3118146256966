import React, { useState } from "react";
import "./Navbar.css";

function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="logo">CCInvest</div>
        <button
          className="menu-toggle"
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          ☰
        </button>
        <ul className={`navbar-list ${isMenuOpen ? "active" : ""}`}>
          {["Home", "Services", "Projects", "Contact"].map((item, index) => (
            <li
              onClick={() => setMenuOpen(!isMenuOpen)}
              key={index}
              className="navbar-item"
            >
              <a className="link_main" href={`#${item.toLowerCase()}`}>
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
