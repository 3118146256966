import React from 'react';
import './Hero.css';

function Hero() {
    return (
        <section className="hero-section">
            <div className="hero-content">
                <h1>Illuminate Your Path with CCINVEST</h1>
                <p>Pioneering Solutions in Medical Supplies, Project Management, and Strategic Implementation</p>
                <button className="cta-button">Explore Our Services</button>
            </div>
        </section>
    );
}

export default Hero;
