import React from "react";
import "./AboutUs.css";
import CompanyImage from "./companyimage.png";

function AboutUs() {
  return (
    <div className="container">
      <div className="content-box">
        <img src={CompanyImage} alt="Company" className="company-image" />
        <div>
          <div className="title">About Us</div>
          <div className="description">
            We are a passionate team dedicated to bringing the best solutions to
            our customers. Grounded in our experience, we are driven by
            innovation, commitment, and a desire to make a difference. Dive in
            and learn more about our journey and what drives us.
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
